* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

body {
  position: relative;
  padding: 0 15px 0 15px;
  font-family: 'Nunito', sans-serif;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: overlay;
  margin: 5px 0 20px 0;
  background: #f1f1f1;
  color: rgb(56, 56, 56);
}

.header {
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
  z-index: -1;
  background: linear-gradient(0deg, rgba(241,241,241,1) 0%, rgba(62,190,148,0.56) 26%, rgba(126,213,111,0.74) 100%), url("./static/tausta2.jpg");
  background-size: cover;
  background-position: top;
  height: 95vh;
  width: 95vw;
  clip-path: polygon(50% 0%, 100% 6%, 100% 100%, 0 100%, 0 6%);
  border-radius: 5px;
}

.text-box {
  width: 100%;
  margin-top: 25px;
}

.textbox-header {
  text-align: center;
}

.header > * {
  color: rgb(56, 56, 56);
}

.header-main {
  display: block;
  animation: moveInLeft 1s ease-out;
  text-align: center;
  font-size: 60px;
  letter-spacing: 20px;
  padding-left: 15px;
}

.header-sub {
  display: inline-block;
  min-width: 90%;
  animation: moveInRight 1s ease-out;
  letter-spacing: 10px;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}

.container-main {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-links {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container-content {
  width: 90%;
  border-radius: 10px;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.8);
  min-height: 450px;
  font-size: 25px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.btn > span {
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn:link,
.btn:visited {
  background:rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  width: 90%;
  max-width: 350px;
  min-height: 30px;
  transition: all .2s;
  margin: 0px 0 8px 0;
  text-align: center;
  vertical-align: center;
  font-size: 20px;
  letter-spacing: 1.5px;
  color: rgb(56, 56, 56);
  text-decoration: none;
  backface-visibility: hidden;
  position: relative;
}

.btn:hover, .btn:active {
  animation: hoverFocus 0.5s ease-out;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background:rgba(255, 255, 255, 0.9)!important;
}

@keyframes hoverFocus{
  0% {
    background:rgba(255, 255, 255, 0.8);
  }

  100% {
    background:rgba(255, 255, 255, 0.9);
  }
}

.header-page-content {
  text-align: center;
  font-size: 35px;
  letter-spacing: 1.5px;
  margin-top: 20px;
  text-transform: uppercase;
}

.bg-lighten {
  background: linear-gradient(200deg, rgba(62,190,148,0.36) 26%, rgba(126,213,111,0.64) 100%)
}

.margin-small {
  margin: 15px 5px 5px 5px;
}

.rounded {
  border-radius: 10px;
}

.info-text {
  padding: 10px 5px 10px 5px;
  font-size: 20px;
}

.partial-border {
  border-left: 3px solid rgb(54, 173, 100);
  border-bottom: 3px solid rgb(54, 173, 100);
}

.info-text-sub-header {
  text-transform: uppercase;
  font-size: 22px;
  margin-bottom: 10px;
}

.header-padded {
  padding: 0 0 .5vw 0;
}

.header-margin-l {
  margin-top: 4vw;
}

.container-index-left {
  padding: 0 20px 0 20px;
  flex: 2;
}

.container-index-right {
  flex: 1;
}

.logo {
  width: 100%;
  max-width: 100px;
}

.logo-text {
  font-size: 15px;
}

.react-images__footer__caption {
  font-size: 25px;
}

@keyframes moveInLeft {
  0% {
      opacity: 0;
      transform: translateX(-100px)
  }

  100% {
      opacity: 100;
      transform: translateX(0);
  }
}

@keyframes moveInRight {
  0% {
      opacity: 0;
      transform: translateX(100px)
  }

  100% {
      opacity: 100;
      transform: translateX(0);
  }
}

@keyframes liftUp {
  0% { transform: translateY(0px) }
  100% { transform: translateY(-3px)} 
}

#index-info-pic {
  max-width: 100%;
  border-radius: 7px;
  display: inline-block;
  clip-path: polygon(0 0, 100% 0%, 100% 96%, 0 86%);
}

h2 {
  font-weight: 200;
  color: rgb(56, 56, 56);
  font-size: 32px;
}

h3 {
  font-size: 25px;
}

.container-image {
  float: right;
}

.container-image > img {
  display: inline-block;
}

.image-gallery-image {
  max-height: 700px!important;
  width: auto;
}


.image-text {
  font-size: 25px;
  text-align: center;
}

.container-information {
  float: left;
}

.white {
  color: whitesmoke
}

.email {
  word-wrap: break-word
}

.container-form {
  width: 50%;
  max-width: 470px;
  border: 3px solid white;
  border-radius: 10px;
  padding: 20px 30px 20px 30px;
  overflow: hidden;
  background: linear-gradient(25deg,rgba(62, 190, 115, 0.56) 5%, rgba(126,213,111,0.74) 100%);
}

.form-row {
  margin-bottom: 13px;
}

.center {
  text-align: center;
}

.margin-small {
  margin-bottom: 5px;
}


.form-row > label {
  font-size: 17px;
  display: block;
  text-transform: uppercase;
  color: rgb(61, 61, 61);
  font-weight: 500;
}

.form-row > p {
  color: rgb(56, 56, 56);
}

.form-row > input, textarea {
  padding: .5rem;
  padding-left: .7rem;
  width: 100%;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
}

.button-submit {
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  text-align: center;
  min-width: 200px;
  height: 37px;
  font-size: 17px;
  text-transform: uppercase;
  border-radius: 3px;
  background: rgba(85, 175, 69, 0.74);
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  transition: all .2s
}

.button-submit:hover {
  transform: translateY(-3px);
  box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.6);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.feedback {
  color: red;
  font-size: 15px;
  padding-left: 8px;
}

.container-slogan {
  width: 100%;
  background: linear-gradient(90deg, rgba(217,217,217,0.56) 0%, rgba(241,241,241,1) 100%);
  border-radius: 5px;
  padding: 15px 0 15px 0;
  text-align: center;
}

.slogan {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 27px;
  color: rgba(128, 128, 128, 0.61);
}

/**** MEDIA ****/

[class*="col-"] {
  float: left;
  padding: 15px;
}

/* mobile cols */
[class*="col-"] {
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .decreased-font {
    font-size: 16px!important;
  }
}

@media only screen and (min-width: 600px) {
  /* tablet cols*/
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
  /* */

  .container-content {
    padding: 20px 40px 40px 40px;
  }

  .info-text-sub-header {
    font-size: 30px;
  }

  .container-slogan {
    margin-top: 20px;
  }

  .container-slogan {
    display: flex;
    align-items: center;
  }

}

@media only screen and (min-width: 768px) {
  /* desktop cols */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
  /* */

  .container-links {
    flex-direction: row;
    width: 90%;
    margin: 40px 0 30px 0;
    max-width: 1100px;
  }

  .btn:link,
  .btn:visited {
    min-height: 60px;
    margin: 0 2px 0 2px;
    font-size: 19px;
  }

  .btn:hover, .btn:active {
    transform: translateY(-5px);
  }

  .btn > span {}

  .header-main {
    font-size: 80px;
  }

  .header-sub {
    font-size: 40px;
  }

  h2 {
    font-size: 40px;
  }

  .container-img {
    margin-top: 55px;
  }

  .container-content {
    padding: 20px 40px 40px 40px;
  }

  .info-text {
    padding: 10px 20px 10px 20px;
  }

  .container-contact-info {
    margin-top: 60px;
  }

  .slogan {
    font-size: 30px;
  }

}